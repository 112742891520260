import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
const Footer = () => {
	const scrollToTop = event => {
		window.scrollTo(0, 0)
	}
	return(
		<Fragment>
			<footer className="text-center">
				<Link to="/" title="Accueil" onClick={ scrollToTop } >
					<i className="fa fa-chevron-up"></i>
					<h5>2018 - 2020 © Willy "Gwyomarch" DECLERCQ</h5>
				</Link>
			</footer>
		</Fragment>
	)
}

export default Footer