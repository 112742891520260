import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import NavBar from './containers/NavBar'
import About from './containers/About'
// import Skills from './containers/Skills'
// import Portfolio from './containers/Portfolio'
// import Formation from './containers/Formation'
// import Experience from './containers/Experience'
// import Certif from './containers/Certif'
// import Contact from './containers/Contact'
import NotFound from './components/NotFound'
import Footer from './containers/Footer'

import asyncComponent from './containers/asyncComponent'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/js/dist/util'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/carousel'

const asyncSkills = asyncComponent(() => import('./containers/Skills'))
const asyncPortfolio = asyncComponent(() => import('./containers/Portfolio'))
const asyncFormation = asyncComponent(() => import('./containers/Formation'))
//const asyncExperience = asyncComponent(() => import('./containers/Experience'))
const asyncCertif = asyncComponent(() => import('./containers/Certif'))
const asyncContact = asyncComponent(() => import('./containers/Contact'))

class App extends Component {
	constructor(props) {
		super(props)
		this.state = {
			prevScrollPos: window.pageYOffset,
			scrolled: false
		}

		this.handleScroll = this.handleScroll.bind(this)
	}
	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll, true)
	}
	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll)
	}
	handleScroll(event) {
		let currentScrollPos = window.pageYOffset
		// console.log(window.pageYOffset)
		if(this.state.prevScrollPos < currentScrollPos){
			this.setState({ scrolled: true })
		} else {
			this.setState({ scrolled: false })
		}
		this.setState({ prevScrollPos: currentScrollPos })
	}
	render() {
	return (
		<div className="App">
			<NavBar scrolled={ this.state.scrolled }/>
			<Switch>
				<Route path="/" component={ About } exact />
				<Route path="/skills" component={ asyncSkills } exact />
				<Route path="/portfolio" component={ asyncPortfolio } exact />
				<Route path="/formation" component={ asyncFormation } exact />
				{/*<Route path="/experience" component={ Experience } exact />*/}
				<Route path="/certif" component={ asyncCertif } exact />
				<Route path="/contact" component={ asyncContact } exact />
				<Route component={ NotFound } />
			</Switch>
			<Footer />
		</div>
	)
	}
}

export default withRouter(App)
