import React from 'react'

const About = () => (

	<div id="about" className="container-fuild">
		<div className="col-xs-8 col-md-4 profile-picture">
			<img src={ '../img/profile-image.jpg' } alt="Willy DECLERCQ" className="rounded-circle"/>
		</div>
		<div className="heading">
			<h1>Willy DECLERCQ (Gwyomarch)</h1>
			<h3>Développeur Web <br/><br/>Javascript<br/><br/></h3>
			<a target="_blank" rel="noopener noreferrer" href="https://www.malt.fr/profile/willydeclercq" className="button1" title="Mon profil Freelance sur la plateforme Malt">Parlons de vos projets...</a>
			<p>Développeur curieux et passionné de technologies, de développement, réseau et cyber sécurité.</p>
			<p>Après 8 ans dans l'accompagnement de personnes handicapées, j'ai entâmé une reconversion dans le développement informatique, découvert Linux (Debian), tenté la piscine de 42 et finalement, je me suis tourné vers le Web avec ReactJs puis le Mobile avec ReactNative en autodidacte.</p>
			<p>Par période, je pratique des tests d'intrusion sur des challenges de cybersécurité CTF sur VulnHub ou HackTheBox.</p>
			<a target="_blank" rel="noopener noreferrer" href="https://github.com/gwyomarch" className="button1" title="Mon profil et certains de mes projets personnels sur GitHub">Mon GitHub</a>
		</div>
	</div>
)

export default About
