import * as actionTypes from './actions'

const initialState = {
		name: '',
		lastname: '',
		email: '',
		phone: '',
		message: ''
}

const reducer = (state = initialState, action) => {
	switch(action.type) {
		case actionTypes.UPDATE:
			// console.log('UPDATE %s = %s', action.field, action.value)
			return {
				...state,
				[action.field]: action.value
			}
		case actionTypes.RESET:
			return initialState
		// case actionTypes.SUBMIT:
		// 	return {
		// 		...state,
		// 		name: action.dataForm.name,
		// 		lastname: action.dataForm.lastname,
		// 		email: action.dataForm.email,
		// 		phone: action.dataForm.phone,
		// 		message: action.dataForm.message
		// 	}
		default:
			return state
	}
}

export default reducer
