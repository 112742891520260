import React from 'react'

const NotFound = () => {
	return(
		<div className="container-fluid" style={{ paddingTop: '100px', height: '100vh', width: '100vw', background: '#ddd' }}>
			<h1 style={{ textAlign: 'center', color: 'red', marginBottom: '280px' }} >Il n'y a rien ici !</h1>
			<a href="/" className="button1 blue back" >Retour</a>
		</div>
	)
}

export default NotFound
