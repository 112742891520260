import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'

const NavItem = props => {
	return(
		<Fragment>
			<li className="nav-item ml-auto">
				<NavLink exact onClick={ props.onClick } activeClassName={ props.active ? 'active' : '' } className="nav-link" to={ props.link }>{ props.title }</NavLink>
			</li>
		</Fragment>
	)
}
export default NavItem