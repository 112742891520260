import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import NavItem from '../components/NavItem'
class NavBar extends Component {

	render(){
		const removeNavbar = event => {
			const menu = document.getElementById('menu')
			menu.classList.remove('show')
			window.scrollTo(0, 0)
		}
		return(
			<nav className={this.props.scrolled ? 'navbar navbar-light navbar-expand-lg fixed-top overTheTop' : 'navbar navbar-light navbar-expand-lg fixed-top'} role="navigation">
				<div className="container ml-auto">
					<div className="navbar-header">
						<button type="button" className="navbar-toggler ml-auto" data-toggle="collapse" data-target="#menu">
							<i className="fa fa-navicon"></i>
						</button>
					</div>
					<div id="menu" className="collapse navbar-collapse">
						<ul className="nav navbar-nav ml-auto" >
							<NavItem onClick={ removeNavbar } link="/" title="WhoAmI" active />
							<NavItem onClick={ removeNavbar } link="/skills" title="Compétences" active />
							<NavItem onClick={ removeNavbar } link="/portfolio" title="Portfolio" active />
							<NavItem onClick={ removeNavbar } link="/formation" title="Formation" active />
							{/* <NavItem onClick={ removeNavbar } link="/experience" title="Expérience" active /> */}
							<NavItem onClick={ removeNavbar } link="/certif" title="Certificats" active />
							<NavItem onClick={ removeNavbar } link="/contact" title="Contact" active />
						</ul>
					</div>
				</div>
			</nav>
		)
	}
}

export default NavBar
